import {Button, Modal } from 'antd';
import React from 'react';
import {Map, Marker} from 'react-amap';

const LocationModal = ({lng, lat, open, onCancel}) => {
    return (
        <Modal cancelText="取消" footer={[
            <Button key="back" onClick={onCancel}>
                取消
            </Button>]} title="定位" width="50vw" open={open} onCancel={onCancel}>
            <div style={{width: '100%', height: "40vh"}}>
                <Map plugins={['ToolBar']} center={{longitude: lng, latitude: lat}} zoom={17}>
                    <Marker position={{longitude: lng, latitude: lat}}/>
                </Map>
            </div>
        </Modal>
    );
};

export default LocationModal;