import React, {useEffect, useState} from 'react';
import {iHydrant} from "../../../interface";
import {deleteChemicals, getChemicalsPageData} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import {Button, Input, Modal, Popconfirm, Table, Pagination} from "antd";
import styles from "../communityComponent/index.module.css";
// @ts-ignore
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import AddChemicalModal from "../../../components/addChemicalModal";
import EditChemicalModal from "../../../components/editChemicalModal";

const Chemicals = () => {
    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [chemicalList, setChemicalList] = useState<iHydrant[]>([])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    const [searchCondition, setSearchCondition] = useState({});
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    let params: any = {}
    const handleGetChemicalList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.name) {
            params.name = searchCondition?.name
        }
        getChemicalsPageData(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setChemicalList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetChemicalList(pageNum, pageSize, newSearchCondition);
    }, [pageSize, pageNum])

    const [record, setRecord] = useState<any>();
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const handleCloseEditModal = () => {
        setEditModalVisible(false);
    }


    const [pdfPages, setPdfPages] = useState<any>(0);
    const [pdfPageNumber, setPdfPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}: { numPages: any }) {
        setPdfPages(numPages);
    }

    const onChangePdfPage = (page: any) => {
        setPdfPageNumber(page);
    };

    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [pdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
    const handleClosePdfModal = () => {
        setPdfModalOpen(false);
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'name'
        },
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: any) => (
                <div>
                    <Button type={'link'} onClick={() => {
                        setRecord(record);
                        setPdfUrl(record?.url);
                        setPdfModalOpen(true);
                    }}>查看详情</Button>

                    <Button type={'link'} onClick={() => {
                        setRecord(record);
                        setEditModalVisible(true);
                    }}>修改名称</Button>

                    {isSuperManager && (
                        <Popconfirm title={"是否确认删除该条数据"} onConfirm={() => {
                            deleteChemicals({id: record?.id}).then((res: any) => {
                                if (res?.code === 200) {
                                    handleGetChemicalList(pageNum, pageSize, newSearchCondition).then(() => {
                                        showSuccess(res?.msg);
                                    })
                                } else {
                                    showFail(res?.msg)
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Button danger type={'link'} color={'danger'}>删除</Button>
                        </Popconfirm>
                    )}
                </div>
            )
        }
    ];

    const handleNameChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            name: e.target.value
        })
    }

    const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
    const handleCloseAddModal = () => {
        setAddModalVisible(false);
    }

    return (
        <div>

            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleNameChange} placeholder={'名称'} allowClear/>
                </div>
                <div className={styles.searchButton}>

                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        handleGetChemicalList(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                        setAddModalVisible(true)
                    }}>新增
                    </Button>

                </div>
            </div>

            <Table rowKey={'id'} columns={columns} dataSource={chemicalList}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            <AddChemicalModal open={addModalVisible} onCancel={handleCloseAddModal} handleGetChemical={async () => {
                handleGetChemicalList(pageNum, pageSize, newSearchCondition)
            }}/>

            <EditChemicalModal open={editModalVisible} onCancel={handleCloseEditModal} record={record} handleGetChemical={async () => {
                               handleGetChemicalList(pageNum, pageSize, newSearchCondition)
                           }}/>


            <Modal
                title={`${record?.name}详情`}
                open={pdfModalOpen}
                onCancel={()=>{
                    handleClosePdfModal();
                    setPdfPageNumber(1)
                }}
                width={"1000px"}
                style={{top: 10}}
            >
                <div style={{
                    boxSizing: "border-box",
                    padding: "16px",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#f3f2f1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>

                    <Document
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pdfPageNumber}/>
                    </Document>
                    <Pagination
                        style={{marginTop: 4, display: 'flex', justifyContent: 'flex-end'}}
                        total={pdfPages as number}
                        simple
                        hideOnSinglePage
                        showTotal={(total) => `共 ${total} 页`}
                        current={pdfPageNumber}
                        pageSize={1}
                        size="small"
                        onChange={onChangePdfPage}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default Chemicals;