import React, {useEffect, useState} from 'react';
import {iInspectTask} from "../../../interface";
import {deleteTask, getTaskDoc, getTaskPage, releaseInspectTask} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import {Button, Form, Input, Modal, Popconfirm, Select, Spin, Table, Tag, TreeSelect} from "antd";
import styles from "../hydrant/index.module.css";
import AddInspectTaskDrawer from "../../../components/addInspectTaskDrawer";
import TaskRecordDrawer from "../../../components/taskRecordDrawer";
import UpdateInspectTaskModal from "../../../components/updateInspectTaskModal";
// @ts-ignore
import {debounce} from "lodash"

const InspectTask = () => {
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [docOpen, setDocOpen] = useState<boolean>(false)
    const [docUrl, setDocUrl] = useState<string>('')
    const [inspectTaskList, setInspectTaskList] = useState<iInspectTask[]>([])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    let params: any = {}
    const handleGetInspectTaskList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.taskId) {
            params.taskId = searchCondition?.taskId
        }
        if (searchCondition?.taskName) {
            params.taskName = searchCondition?.taskName
        }
        if (searchCondition?.taskState !== "") {
            params.taskState = searchCondition?.taskState
        }
        getTaskPage(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setInspectTaskList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetInspectTaskList(pageNum, pageSize, newSearchCondition);
    }, [pageSize, pageNum])

    const [searchCondition, setSearchCondition] = useState({
        taskId: "",
        taskName: "",
        taskState: "",
    });

    const stateOptions = [
        {value: 0, label: "未发布"},
        {value: 1, label: "待巡检"},
        {value: 2, label: "巡检完成"},
    ]

    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);


    const handleIdChange = (e: any) => {
        setSearchCondition({...searchCondition, taskId: e?.target?.value})
    }

    const handleNameChange = (e: any) => {
        setSearchCondition({...searchCondition, taskName: e?.target?.value})
    }

    const handleTaskStateChange = (v: any) => {
        setSearchCondition({...searchCondition, taskState: v})
    }


    const columns: any = [

        {
            title: '任务id',
            dataIndex: 'taskId',
            key: 'taskId',
            fixed: 'left'
        },
        {
            title: '名称',
            dataIndex: 'taskName',
            key: 'taskName',
            fixed: 'left',
            render: (taskName: string) => (<div>
                {taskName || (<div style={{color: '#e5b549'}}>未发布的周期任务</div>)}
            </div>)
        },
        // {
        //     title: '类型',
        //     dataIndex: 'taskType',
        //     key: 'taskType'
        // },
        {
            title: '状态',
            dataIndex: 'taskState',
            key: 'taskState',
            render: (taskState: any) => (
                <div>
                    {taskState === 0 ? <Tag color={'blue'}>未发布</Tag> :
                        taskState === 1 ? <Tag color={'orange'}>巡检中</Tag> :
                            <Tag color={'green'}>巡检完成</Tag>}
                </div>
            )
        },
        {
            title: '完成率',
            dataIndex: 'completeRate',
            key: 'completeRate',
            render: (completeRate: any) => (
                <div>
                    {completeRate ?(completeRate?.length>5? `${completeRate.slice(0, 5)}%`:completeRate) : "0%"}
                </div>
            )
        },
        {
            title: '负责人',
            dataIndex: 'sysUser',
            key: 'sysUser',
            render: (sysUser: any) => (
                <div>{sysUser?.username} {sysUser?.mobile}
                </div>
            )
        },
        {
            title: '任务起止时间',
            dataIndex: 'time',
            key: 'time',
            width: 180,
            render: (_: any, {taskStartTime, taskEndTime}: any) => (
                <div>
                    {(taskStartTime && taskEndTime) ? (<div>
                        {taskStartTime.slice(0, -3)} → {taskEndTime.slice(0, -3)}
                    </div>) : (
                        <div style={{color: '#e5b549'}}>未发布的周期任务</div>
                    )}
                </div>
            )
        },
        {
            title: '完成时间',
            dataIndex: 'taskCompleteTime',
            key: 'taskCompleteTime'
        },
        {
            title: '是否为周期任务',
            dataIndex: 'regularInspectionId',
            key: 'regularInspectionId',
            render: (regularInspectionId: any) => (
                <div>
                    {regularInspectionId ? (<Tag color={'green'}>是</Tag>) :
                        (<Tag color={'blue'}>否</Tag>)}
                </div>
            )
        },
        {
            title: '备注',
            dataIndex: 'taskRemark',
            key: 'taskRemark',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            fixed: 'right',
            width: 350,
            render: (_: any, record: any) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div>
                        <Button disabled={record?.taskState !== 0} type={'link'} onClick={() => {
                            if (record?.regularInspectionId) {
                                Modal.warning({
                                    title: "提示",
                                    content: `该任务为周期任务, 提前发布需至定期计划页面发布对应计划, 计划id为${record?.regularInspectionId}`
                                })
                            } else {
                                Modal.confirm({
                                    title: '是否确认发布该任务?',
                                    content: "任务到开始时间会自动发布, 是否提前发布该任务?",
                                    onOk: debounce(() => {
                                        releaseInspectTask({taskId: record?.taskId}).then((res: any) => {
                                            if (res?.code === 200) {
                                                handleGetInspectTaskList(pageNum, pageSize, newSearchCondition).then(() => {
                                                    showSuccess(res?.msg);
                                                })
                                            } else {
                                                showFail(res?.msg)
                                            }
                                        }).catch((err) => {
                                            console.log(err)
                                        })
                                    }, 1000)
                                })
                            }
                        }}>手动发布任务</Button>
                        <Button type={'link'} onClick={() => {
                            getTaskDoc({taskId:record.taskId}).then(
                                (res:any)=>{
                                    if(res?.code==200){
                                        setDocUrl(res.msg)
                                        setDocOpen(true)
                                    }else {
                                        showFail(res?.msg)
                                    }
                                }
                            )}
                        }>任务报告</Button>
                    </div>

                    <div>
                        <Button type={'link'} onClick={() => {
                            setRecordDrawerVisible(true);
                            setTask(record);
                        }}>巡检清单</Button>
                    </div>

                    <div>
                        <Button type={'link'} disabled={record?.taskState === 0 && record?.regularInspectionId}
                                onClick={() => {
                                    setTask(record);
                                    setUpdateModalVisible(true)
                                }}>修改</Button>
                    </div>

                    <div>
                        <Popconfirm title={"是否确认删除该巡检任务"} placement={'topLeft'} onConfirm={
                            debounce(() => {
                                deleteTask({taskId: record?.taskId}).then((res: any) => {
                                    if (res?.code === 200) {
                                        handleGetInspectTaskList(pageNum, pageSize, newSearchCondition).then(() => {
                                            showSuccess(res?.msg);
                                        })
                                    } else {
                                        showFail(res?.msg)
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })
                            }, 1000)}
                        >
                            <Button danger type={'link'} color={'danger'}>删除</Button>
                        </Popconfirm>
                    </div>


                </div>
            )
        },
    ];

    //新增
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
    const handleCloseAddModal = () => {
        setAddModalVisible(false);
    }

    //巡检记录
    const [task, setTask] = useState<iInspectTask | undefined>();
    const [recordDrawerVisible, setRecordDrawerVisible] = useState<boolean>(false);
    const handleCloseRecordDrawer = () => {
        setRecordDrawerVisible(false);
    }


    //修改
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
    const handleCloseUpdateModal = () => {
        setUpdateModalVisible(false);
    }

    return (
        <div className={styles.main}>
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleIdChange} placeholder={'任务id'} allowClear/>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleNameChange} placeholder={'任务名称'} allowClear/>


                    <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            options={stateOptions} placeholder={'任务状态'}
                            onChange={handleTaskStateChange} allowClear/>
                </div>

                <div className={styles.searchButton}>
                    <Button style={{marginRight: '20px'}} type='primary' onClick={() => {
                        handleGetInspectTaskList(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    <Button type='primary' onClick={() => {
                        setAddModalVisible(true);
                    }}>新增
                    </Button>
                </div>

            </div>
            <Table rowKey={'taskId'} scroll={{x: 1500}} columns={columns} dataSource={inspectTaskList}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            <AddInspectTaskDrawer open={addModalVisible} onClose={handleCloseAddModal}
                                  handleGetInspectTask={async () => {
                                      await handleGetInspectTaskList(pageNum, pageSize, newSearchCondition)
                                  }}/>

            <TaskRecordDrawer open={recordDrawerVisible} onCancel={handleCloseRecordDrawer}
                              task={task as iInspectTask}/>


            <UpdateInspectTaskModal open={updateModalVisible} onClose={handleCloseUpdateModal}
                                    handleGetInspectTask={async () => {
                                        await handleGetInspectTaskList(pageNum, pageSize, newSearchCondition)
                                    }} inspectTask={task as iInspectTask}/>

            <Modal
                title='巡检任务报告预览'
                open={docOpen}
                onCancel={()=>{setDocOpen(false)}}
                // onOk={onAddDept}
                width={"1000px"}
                footer={[]}
                style={{ top: 10 }}
            >
                <div style={{ boxSizing: "border-box", padding: "16px", width: "100%", height: "100%", backgroundColor: "#f3f2f1", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    <iframe style={{width: "100%", height: "800px"}}  title={"docViewer"} src={"https://view.officeapps.live.com/op/view.aspx?src="+docUrl+"&timestamp="+Date.now()}  />
                </div>
            </Modal>
        </div>
    );
};

export default InspectTask;