import React, {useEffect, useState} from 'react';
import {iHydrant} from "../../../interface";
import {deleteCommunity, deleteDict, getDictPageData, getHydrantPage} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import {Button, Cascader, Input, Popconfirm, Table} from "antd";
import styles from "../communityComponent/index.module.css";
import AddDictModal from "../../../components/addDictModal";
import EditDictModal from "../../../components/editDictModal";

const Dict = () => {
    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [dictList, setDictList] = useState<iHydrant[]>([])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    const [searchCondition, setSearchCondition] = useState({

    });
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    let params: any = {}
    const handleGetDictList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        params.isLook = 1;
        if (searchCondition?.model) {
            params.model = searchCondition?.model
        }
        if (searchCondition?.attribute) {
            params.attribute = searchCondition?.attribute
        }
        if (searchCondition?.value) {
            params.value = searchCondition?.value
        }
        getDictPageData(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setDictList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetDictList(pageNum, pageSize, newSearchCondition);
    }, [pageSize, pageNum])

    const [dictRecord,setDictRecord]=useState<any>();
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const handleCloseEditModal = () => {
        setEditModalVisible(false);
    }

    const columns = [
        {
            title: '类型',
            dataIndex: 'model',
        },
        {
            title: '名称',
            dataIndex: 'attribute'
        },
        {
            title: '内容',
            dataIndex: 'value'
        },
        {
            title: '描述',
            dataIndex: 'description'
        },
        {
            title: '序号',
            dataIndex: 'dictOrder'
        },
        {
            title: '操作',
            dataIndex: 'operation',
            render:(_:any,record:any)=>(
                <div>
                    <Button type={'link'} onClick={()=>{
                        setDictRecord(record);
                        setEditModalVisible(true);
                    }}>修改</Button>

                    {isSuperManager && (
                        <Popconfirm title={"是否确认删除该条数据"} onConfirm={() => {
                            deleteDict({id: record?.id}).then((res: any) => {
                                if (res?.code === 200) {
                                    handleGetDictList(pageNum, pageSize, newSearchCondition).then(() => {
                                        showSuccess(res?.msg);
                                    })
                                } else {
                                    showFail(res?.msg)
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Button danger type={'link'} color={'danger'}>删除</Button>
                        </Popconfirm>
                    )}
                </div>
            )
        }
    ];

    const handleModelChange=(e:any)=>{
        setSearchCondition({
            ...searchCondition,
            model: e.target.value
        })
    }

    const handleAttributeChange=(e:any)=>{
        setSearchCondition({
            ...searchCondition,
            attribute: e.target.value
        })
    }

    const handleValueChange=(e:any)=>{
        setSearchCondition({
            ...searchCondition,
            value: e.target.value
        })
    }

    const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
    const handleCloseAddModal = () => {
        setAddModalVisible(false);
    }

    return (
        <div>

            <div className={styles.mainHead}>
                <div className={styles.searchContent}>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleModelChange} placeholder={'类型'} allowClear/>

                    <Input onChange={handleAttributeChange} style={{width: 250, marginRight: '30px', marginTop: '10px'}}
                           placeholder="名称" allowClear/>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleValueChange} placeholder={'内容'} allowClear/>

                </div>
                <div className={styles.searchButton}>

                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        handleGetDictList(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                        setAddModalVisible(true)
                    }}>新增
                    </Button>

                </div>
            </div>


            <Table rowKey={'id'} columns={columns} dataSource={dictList}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            <AddDictModal open={addModalVisible} onCancel={handleCloseAddModal} handleGetDict={async ()=>{
                handleGetDictList(pageNum, pageSize, newSearchCondition)
            }}/>

            <EditDictModal  open={editModalVisible} onCancel={handleCloseEditModal} record={dictRecord} handleGetDict={async ()=>{
                handleGetDictList(pageNum, pageSize, newSearchCondition)
            }}/>
        </div>
    );
};

export default Dict;