import React, {useEffect, useState} from 'react';
import {
    Button,
    Divider,
    Input,
    message,
    Modal,
    Popconfirm,
    Select,
    Space,
    Table,
    TreeSelect,
    Form, Spin
} from "antd";
import {AxiosError} from "axios";
import {PlusOutlined, TeamOutlined} from "@ant-design/icons";
import {
    addDept,
    getDeptTree,
    deleteDept,
    updateDept,
    searchByMobile, searchUserById, getAreaTree
} from "../../../api";
const { Option } = Select;

//菜单管理
const DeptManagement = () => {

    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    interface depttree {
        value?: string,
        title?: string,
        children?: any[],
    }
    //保存数据
    const onAddDept = (e:any) =>{
            setAddDeptS(true)
          let params = e
            console.log(params)
            addDept(params).then( (res: any) => {
                if (/^5[0-9][0-9]/.test(res.code)) {
                    message.error(res.msg);
                    form2.resetFields()
                    setAddDeptS(false)
                } else {
                    form2.resetFields()
                    message.success(res.msg);
                    setIsAddModalOpen(false);
                    setAddDeptS(false)
                    onGetDept();
                }
            }, (err: AxiosError) => {
                console.log(err);
                setAddDeptS(false)
            })
        }

    const [parentId,setParentId]=useState();
    const [addDeptS,setAddDeptS]=useState<boolean>(false)
    const [updateDeptS,setUpdateDeptS]=useState<boolean>(false)
    const [deptTree, setDeptTree] = useState<any>([]);
    const [depts, setDepts] = useState<any>([]);
    function tree(list:depttree[],res:any[]){
        console.log(res)
        for (let i = 0; i < res.length; i++) {
            if(res[i].children){
                // @ts-ignore
                list.push({value: res[i].deptId, title: res[i].name, children: []})
            }else {
                // @ts-ignore
                list.push({value: res[i].deptId, title: res[i].name})
            }
            // @ts-ignore
            if(res[i].children) {
                // @ts-ignore
                tree(list[i].children, res[i].children)
            }
        }
        return list
    }


    const [sysUsers,setSysUsers]=useState<any[]>([])
    const [sysDepartUsers,setSysDepartUsers]=useState<any[]>([])
    const [sysCompanyUsers,setSysCompanyUsers]=useState<any[]>([])
    const [sysAssetMangers,setSysAssetMangers]=useState<any[]>([])
    const changeMobile=(e:any)=>{
        let inputMobile=e.target.value
        console.log(/^[0-9]+$/.test(inputMobile))
        if(/^[0-9]+$/.test(inputMobile) && inputMobile>0 && inputMobile.toString().length==11){
            let params={mobile:inputMobile}
            searchByMobile(params).then((res:any)=>{
                setSysUsers(res.data)
            })
        }else if(/^[\u4e00-\u9fa5][\u4e00-\u9fa5].*$/.test(inputMobile)){
            let params={username:inputMobile}
            searchByMobile(params).then((res:any)=>{
                setSysUsers(res.data)
            })
        }
    }
    const changeMobile2=(e:any,type:any)=>{
        if(type==1) {
            let inputMobile = e.target.value
            console.log(/^[0-9]+$/.test(inputMobile))
            if (/^[0-9]+$/.test(inputMobile) && inputMobile > 0 && inputMobile.toString().length == 11) {
                let params = {mobile: inputMobile}
                searchByMobile(params).then((res:any) => {
                    setSysDepartUsers(res.data)
                })
            } else if (/^[\u4e00-\u9fa5][\u4e00-\u9fa5].*$/.test(inputMobile)) {
                let params = {username: inputMobile}
                searchByMobile(params).then((res:any) => {
                    setSysDepartUsers(res.data)
                })
            }
        }
        if(type==2) {
            let inputMobile = e.target.value
            console.log(/^[0-9]+$/.test(inputMobile))
            if (/^[0-9]+$/.test(inputMobile) && inputMobile > 0 && inputMobile.toString().length == 11) {
                let params = {mobile: inputMobile}
                searchByMobile(params).then((res:any) => {
                    setSysCompanyUsers(res.data)
                })
            } else if (/^[\u4e00-\u9fa5][\u4e00-\u9fa5].*$/.test(inputMobile)) {
                let params = {username: inputMobile}
                searchByMobile(params).then((res:any) => {
                    setSysCompanyUsers(res.data)
                })
            }
        }
        if(type==3) {
            let inputMobile = e.target.value
            console.log(/^[0-9]+$/.test(inputMobile))
            if (/^[0-9]+$/.test(inputMobile) && inputMobile > 0 && inputMobile.toString().length == 11) {
                let params = {mobile: inputMobile}
                searchByMobile(params).then((res:any) => {
                    setSysAssetMangers(res.data)
                })
            } else if (/^[\u4e00-\u9fa5][\u4e00-\u9fa5].*$/.test(inputMobile)) {
                let params = {username: inputMobile}
                searchByMobile(params).then((res:any) => {
                    setSysAssetMangers(res.data)
                })
            }
        }
    }
    interface areatree {
        value?: string,
        title?: string,
        children?: any[],
    }

    useEffect(()=>{

        let params = {}
        getDeptTree(params).then( (res:any)=>{
            setDeptTree(res.data);
            let a:depttree[]=[]
            let b=[]
            b.push(res.data)
            let list=tree(a,b)
           setDepts(list)
            console.log(list)
        }, (err:AxiosError)=>{
            console.log(err);
        });
        getAreaTree(params).then( (res:any)=>{
            setAreaTree(res.data);
            let a:areatree[]=[]
            let b=[]
            b.push(res.data)
            let list=treeArea(a,b)
            setAreas(list)
            console.log(list)
        }, (err:AxiosError)=>{
            console.log(err);
        });

    },[])

    const onGetDept = () => {
        let params = {}
       getDeptTree(params).then( (res:any)=>{
            setDeptTree(res.data);
            console.log(res.data)
        }, (err:AxiosError)=>{
            console.log(err);
        });
    }


    //新增对话框
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const handleAddCancel = () => {
        setIsAddModalOpen(false);
    };
    const [currentData, setCurrentData] = useState<any>('');
    //新增部门
    const handleChange=(e:any)=>{
        setParentId(e)
        console.log(e)
    }


    //删除部门
    const onDeleteDept = (val: any)=>{
        let params = {
            deptId: val.deptId,
        }
        console.log(params)

        deleteDept(params).then ((res:any)=> {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
            }else {
                message.success(res.msg);
                onGetDept();
            }
        }, (err:AxiosError)=>{
            console.log(err);
        })
    }

    //修改对话框
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

    const handleUpdateCancel = () => {
        setIsUpdateModalOpen(false);
    };

    const [updateName, setUpdateName] = useState<string>('');
    const onUpdateDept = (e:any) =>{
        setUpdateDeptS(true)
        let params=e
         params.deptId  = currentData.deptId;
        console.log(params)
        updateDept(params).then ((res:any)=> {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
                setUpdateDeptS(false)
            }else {
                message.success(res.msg);
                setUpdateDeptS(false)
                setIsUpdateModalOpen(false);
                onGetDept();
            }
        }, (err:AxiosError)=>{
            setUpdateDeptS(false)
            console.log(err);
        })
    };


    //表头数据
    // @ts-ignore
    const Columns = [
        {
            title: '部门名称',
            dataIndex: 'name',

        },
        {
            title: '归属区域',
            dataIndex: ['area','name'],

        },

        {
            title: '操作',
            dataIndex: 'operate',
            render: (_: any, record: any,index:any) =>(

                    <Space>
                        {record.deptType!=0?
                        <Popconfirm title="确认删除吗?" onConfirm={() => onDeleteDept(record)}>
                            <a>删除</a>
                        </Popconfirm>:''}
                        {record.deptType!=0?
                        <a onClick={()=> {
                            console.log(record)
                            form.setFieldsValue(
                                { name:record.name,
                                    companyUserId:record.companyUserId,
                                    departUserId:record.departUserId,
                                    assetManagerId:record.assetManagerId,
                                    budgetAmount:record.budgetAmount,
                                    remainingAmount:record.remainingAmount,
                                    limitAmount:record.limitAmount,
                                    areaId:record.areaId
                                //
                                })
                            setCurrentData(record);
                            setIsUpdateModalOpen(true);
                            searchUserById({userId:record.departUserId}).then((res:any)=>{
                                let x=[]
                                x.push(res.data)
                                setSysDepartUsers(x)
                            })
                            searchUserById({userId:record.companyUserId}).then((res:any)=>{
                                let x=[]
                                x.push(res.data)
                                setSysCompanyUsers(x)
                            })
                            searchUserById({userId:record.assetManagerId}).then((res:any)=>{
                                let x=[]
                                x.push(res.data)
                                setSysAssetMangers(x)
                            })
                        }}>修改</a>:''}
                    </Space>
            )
        },

    ]
    const [areas, setAreas] = useState<any>([]);

    const [areaTree, setAreaTree] = useState<any>([]);
    function treeArea(list:areatree[],res:any[]){
        console.log(res)
        for (let i = 0; i < res.length; i++) {
            if(res[i].children){
                // @ts-ignore
                list.push({value: res[i].areaId, title: res[i].name, children: []})
            }else {
                // @ts-ignore
                list.push({value: res[i].areaId, title: res[i].name})
            }
            // @ts-ignore
            if(res[i].children) {
                // @ts-ignore
                treeArea(list[i].children, res[i].children)
            }
        }
        return list
    }
    return (
        <div className='user_management_layout'>
            <div className='user_management_header' style={{marginBottom: '12px' }}><TeamOutlined/> 部门管理</div>
            <Divider style={{margin: '0' }}/>
            <div className='user_management_add'>
                <Button onClick={()=>{setIsAddModalOpen(true)}} type="primary" ><PlusOutlined />新增</Button>
            </div>
            <Table
                rowKey="id"
                columns={Columns}
                dataSource={[deptTree]}
            />


            <Modal
                title='新增部门'
                open={isAddModalOpen}
                onCancel={handleAddCancel}
                // onOk={onAddDept}
                footer={[]}
            >
                <Spin spinning={addDeptS}>
                    <Form
                        onFinish={(e:any)=>onAddDept(e)}
                        name="basic"
                        form={form2}
                    >
                        <Form.Item name={'name'} label={"部门名"} rules={[{ required: true, message: '请输入部门名！' }]}><Input  style={{width: '300px', marginBottom: '10px'}} /></Form.Item>
                        <Form.Item name={'parentId'} label={"归属部门"} rules={[{ required: true, message: '请输入归属部门！' }]}><TreeSelect
                                showSearch
                                style={{ width: '100%' }}
                                // value={deptTree}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="归属部门"
                                allowClear
                                treeDefaultExpandAll
                                onChange={(e)=>handleChange(e)}
                                treeData={depts}
                            /></Form.Item>
                        <Form.Item name={'areaId'} label={"归属区域"} rules={[{ required: true, message: '请输入归属区域！' }]}><TreeSelect
                            showSearch
                            style={{ width: '100%' }}
                            // value={areaTree}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="归属区域"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(e)=>handleChange(e)}
                            treeData={areas}
                        /></Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form>
                </Spin>
            </Modal>

            <Modal
                title='修改部门'
                open={isUpdateModalOpen}
                onCancel={handleUpdateCancel}
                footer={[]}
            >
                <Spin spinning={updateDeptS}>
                    <Form
                        name="basic"
                        form={form}
                        onFinish={(e:any)=>onUpdateDept(e)}
                    >
                        <Form.Item name={'name'} label={"部门名"}><Input  style={{width: '300px', marginBottom: '10px'}} onChange={(e)=>setUpdateName(e.target.value)} /></Form.Item>
                        <Form.Item name={'areaId'} label={"归属区域"} rules={[{ required: true, message: '请输入归属区域！' }]}><TreeSelect
                            showSearch
                            style={{ width: '100%' }}
                            // value={areaTree}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="归属区域"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(e)=>handleChange(e)}
                            treeData={areas}
                        /></Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default DeptManagement;