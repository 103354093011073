import React, {useState} from 'react';
import {Form, Input, Modal, Select} from "antd";
import {Map, Marker} from "react-amap";
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showNetError, showSuccess} from "../functions";
import {addPoint} from "../../api";
import {iCommunity} from "../../interface";

const AddNewPoiModal = ({
                            open,
                            onCancel,
                            community,
                            handleGetCommunity
                        }: { open: boolean, onCancel: () => void, community:iCommunity, handleGetCommunity?: any }) => {
    const [form] = Form.useForm();
    const [pointedLoc, setPointedLoc] = useState({lng: 0, lat: 0});

    const handleAdd = () => {
        form.validateFields()
            .then((value) => {
                let data={
                    ...value,
                    pointLng:value?.pointLocation?.lng,
                    pointLat:value?.pointLocation?.lat,
                    communityId:community?.communityId
                }
                addPoint(data)
                    .then((res:any)=>{
                        if(res?.code===200){
                            handleGetCommunity().then(()=>{
                                onCancel();
                                form.resetFields();
                                setPointedLoc({lng: 0, lat: 0})
                                showSuccess(res?.msg)
                            });
                        }else{
                            showFail(res?.msg)
                        }
                    })
                    .catch((err:any)=>{
                        console.log(err);
                        showNetError()
                    })
            })
            .catch((err: any) => {
                console.log(err);
                showNeedCompleteInfo();
            })
    }

    return (
        <Modal open={open} onCancel={() => {
            onCancel();
            setPointedLoc({lng: 0, lat: 0});
            form.resetFields()
        }} onOk={debounce(handleAdd, 500)} okText={'确认新增'} title={'点位新增'}>
            <div style={{width: '100%', height: "40vh", marginBottom: '20px'}}>
                <Map events={{
                    click: (v: any) => {
                        setPointedLoc({lng: v?.lnglat?.lng, lat: v?.lnglat?.lat});
                        form.setFieldsValue({pointLocation: {lng: v?.lnglat?.lng, lat: v?.lnglat?.lat}})
                    }
                }}
                     center={{longitude: Number(community?.communityLng), latitude: Number(community?.communityLat)}}
                     zoom={17}
                >
                    {pointedLoc?.lat !== 0 && (
                        <Marker position={{longitude: pointedLoc?.lng, latitude: pointedLoc?.lat}}/>
                    )}
                </Map>
            </div>
            <Form form={form} name="form" labelAlign="right" labelCol={{
                xs: {span: 24},
                sm: {span: 6},
            }} wrapperCol={{
                xs: {span: 24},
                sm: {span: 14},
            }}>
                <Form.Item
                    name="pointType"
                    label="类型"
                    rules={[
                        {
                            required: true, message: "请选择点位类型"
                        },
                    ]}
                >
                    <Select options={[
                        {value: "出入口", label: "出入口"},
                        {value: "停靠点", label: "停靠点"},
                        {value: "消控室", label: "消控室"},
                    ]}/>
                </Form.Item>

                <Form.Item
                    name="pointLocation"
                    label="经纬度"
                    rules={[
                        {
                            required: true, message: "请在地图上点击选择坐标!"
                        },
                    ]}
                >
                    {pointedLoc?.lat !== 0 && pointedLoc?.lng !== 0 ? (
                        <div>{pointedLoc?.lng},{pointedLoc?.lat}</div>
                    ) : (<div>
                        请在地图上点击选择坐标
                    </div>)}
                </Form.Item>

                <Form.Item
                    name="pointName"
                    label="名称"
                    rules={[
                        {
                            required: true, message: "请输入点位名称!"
                        },
                    ]}
                >
                    <Input placeholder={'请输入点位名称'}/>
                </Form.Item>


                <Form.Item
                    name="pointRemark"
                    label="备注"
                >
                    <Input.TextArea placeholder={'请输入点位备注'}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default AddNewPoiModal;