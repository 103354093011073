import {Modal, Tag, Image, Button} from 'antd';
import React, {useState} from 'react';
import {iHiddenDanger, iInspectRecord, iRecordPicture} from "../../interface";
import styles from "./index.module.css";
import {getHydrantList} from "../../api";
import {showFail} from "../functions";

const InspectRecordDetailModal = ({
                                      open,
                                      onCancel,
                                      inspectRecord
                                  }: { open: boolean, onCancel: () => void, inspectRecord: iInspectRecord }) => {
    const [imageVisible, setImageVisible] = useState<boolean>(false);
    const [hydrantPictures, setHydrantPictures] = useState<[]>([]);
    return (
        <>
            <Image.PreviewGroup
                items={hydrantPictures}
                preview={{
                    visible: imageVisible,
                    onVisibleChange: (value) => {
                        setImageVisible(value);
                    },
                }}
            >
                {hydrantPictures.map((image: any) => (
                    <Image
                        key={image.key}
                        width={200}
                        height={200}
                        style={{display: 'none'}}
                        src={image.src}
                        preview={{
                            scaleStep: 0.5,
                            src: image.src,
                        }}
                    />
                ))}
            </Image.PreviewGroup>
            <Modal open={open} onCancel={onCancel} title={'巡检记录详情'}
                   cancelButtonProps={{style: {display: 'none'}}} okText={'知道了'} onOk={onCancel}>
                <div>

                    <div className={styles.row}>
                        <div className={styles.title}>完成时间</div>
                        <div className={styles.content}>{inspectRecord?.recordCompleteTime}</div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.title}>外观</div>
                        <div className={styles.content}>{inspectRecord?.appearanceOk === 0 ?
                            <Tag color={'red'}>异常</Tag> : <Tag color={'green'}>正常</Tag>}</div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.title}>水压</div>
                        <div className={styles.content}>{inspectRecord?.pressureOk === 0 ?
                            <Tag color={'red'}>异常</Tag> : <Tag color={'green'}>正常</Tag>}</div>
                    </div>


                    <div className={styles.row}>
                        <div className={styles.title}>是否存在隐患</div>
                        <div className={styles.content}>{inspectRecord?.isDanger === 1 ?
                            <Tag color={'red'}>是</Tag> : <Tag color={'green'}>否</Tag>}</div>
                    </div>

                    {inspectRecord?.isDanger === 1 && (
                        <>
                            {inspectRecord?.hiddenDangers?.map((i: iHiddenDanger, index: number) => (
                                <div className={styles.row}>
                                    <div className={styles.title}>隐患{index + 1}</div>
                                    <div className={styles.content}>
                                        类型:{i?.dangerType};类型:{i?.dangerLevel};描述:{i?.dangerName}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}

                    {inspectRecord?.recordRemark && (
                        <div className={styles.row}>
                            <div className={styles.title}>描述</div>
                            <div className={styles.content}>{inspectRecord?.recordRemark}</div>
                        </div>
                    )}

                    <div className={styles.row} style={{alignItems: 'center'}}>
                        <div className={styles.title}>照片</div>
                        <div className={styles.content}>
                            <Button type={'link'} onClick={() => {
                                getHydrantList({hydrantId: inspectRecord?.hydrantId}).then((res: any) => {
                                    if(res?.code===200){
                                        setHydrantPictures(res?.data[0]?.pictures?.map((u: any) => {
                                            return {
                                                key: u?.pictureId,
                                                src: u?.url
                                            }
                                        }));
                                    }else{
                                        showFail(res?.msg)
                                    }
                                })
                                setImageVisible(true)
                            }}>查看</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>

    );
};

export default InspectRecordDetailModal;