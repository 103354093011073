import React from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Upload, message} from 'antd';

const UpdatePictures = (prop) => {
    const {value, onChange, defaultList, max, type, id, name} = prop;
    let defaultFileList = defaultList?.map((d) => {
        return {uid: d?.pictureId, url: d?.url, name: d?.pictureName}
    })
    const handleChange = ({fileList}) => {
        onChange?.([...fileList]);
    };
    const onRemove = (file) => {
        const files = (value || []).filter((v) => v.url !== file.url);
        if (onChange) {
            onChange(files);
        }
    };


    const getExtraData = (file) => ({
        image: file
    });

    let url = "";
    if (process.env.NODE_ENV === 'development') {
        url = "http://localhost:8006/api";
    } else {
        url = "https://www.huxf.com.cn/api";
    }
    const uploadProps = {
        listType: "picture",
        name: 'file',
        fileList: value,
        action: type === 1 ? `${url}/picture/upload/${id}/${name}` :type===2?
            `${url}/community/upload/${id}/${name}`:`${url}/floorPicture/upload/${id}/${name}`,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        headers: {
            Authorization: window.localStorage.getItem('token')
        },
        maxCount: max,
        beforeUpload: (file) => {
            const isPNGorJPG = file?.type === 'image/png' || file.type === "image/jpeg";
            if (!isPNGorJPG) {
                message.error(`仅支持上传JPG或PNG格式的照片`);
            }
            return isPNGorJPG || Upload.LIST_IGNORE;
        },
        defaultFileList

    };
    return (
        <Upload {...uploadProps}>
            <Button icon={<UploadOutlined/>}>{type === 1?"上传(覆盖原图片)":"上传"}</Button>
        </Upload>
    );
};

export default UpdatePictures;