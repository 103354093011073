import React, {useState} from 'react';
import {Button, DatePicker, Divider, Drawer, Form, Input, message, Modal, Select, Space, Table} from "antd";
// @ts-ignore
import {debounce} from "lodash"
import moment from "moment";
import AddTaskCommunitiesModal from "../addTaskCommunitiesModal";
import {getAreaPath, showFail, showNeedCompleteInfo, showSuccess} from "../functions";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {addExercise, addHiddenDangerTask} from "../../api";
import PickLocationModal from '../pickLocationModal';
import PickUser from "../pickUser";

const AddExerciseTaskDrawer = ({
                                   open,
                                   onCancel,
                                   type,
                                   handleGetTask
                               }: { open: boolean, onCancel: () => void, type: number, handleGetTask: any }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();


    const columns: any = [
        {
            title: '名称',
            dataIndex: 'communityName',
            key: 'communityName',
        },
        {
            title: '地址',
            dataIndex: 'communityAddress',
            key: 'communityAddress'
        },
        {
            title: '二维码',
            dataIndex: 'qrCode',
            key: 'qrCode',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (_: any, {communityId}: any) => (
                <Button type={'link'} danger onClick={() => {
                    setObjectList((pre: any) => {
                        let remainArr = pre?.filter((a: any) => a?.communityId !== communityId);
                        return remainArr
                    })
                }}>删除</Button>
            )
        },]

    const [objectList, setObjectList] = useState<any[]>([]);
    const handleInsertObjectList = (records: any[]) => {
        setObjectList((prevState: any) => {
            return [...prevState, ...records];
        });
        setAddObjectModal(false);
        showSuccess('添加成功')
    }


    const [addObjectModal, setAddObjectModal] = useState<boolean>(false);
    const handleCloseAddObjectModal = () => {
        setAddObjectModal(false);
    }

    const pushData = (values: any) => {
        setLoading(true);
        if (type === 1) {
            addHiddenDangerTask(values).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    handleGetTask().then(() => {
                        showSuccess(res?.msg);
                        onCancel();
                        form.resetFields();
                        setObjectList([]);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })
        } else {
            addExercise(values).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    handleGetTask().then(() => {
                        showSuccess(res?.msg);
                        onCancel();
                        form.resetFields();
                        setObjectList([]);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })
        }

    }

    const handleAddNewTask = () => {
        console.log('objectList', objectList);
        form.validateFields().then((value) => {
            let newInspectTask = {
                ...value,
                taskStartDate: value?.taskTime[0]?.$d.valueOf(),
                taskEndDate: value?.taskTime[1]?.$d.valueOf(),
                communityLng: value?.location?.lng,
                communityLat: value?.location?.lat,
            }
            pushData(newInspectTask);
        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })

    }


    const handleTaskTimeChange = (dates: any, dateStrings: [string, string]) => {
        if (dates?.length > 0) {
            let startTime = dates[0].$d.valueOf();
            const now = new Date();
            const nextHour = new Date(now.getTime() + 60 * 60 * 1000);
            if (startTime > nextHour) {
                form.setFieldsValue({taskTime: dates})
            } else {
                let nextHourStr = moment(nextHour).format('YYYY-MM-DD HH:mm:ss')
                message.warning({content: `任务开始时间必须晚于${nextHourStr}(当前时间的后一小时)`, duration: 2.5})
                form.setFieldsValue({taskTime: ""})
            }
        } else {
            form.setFieldsValue({taskTime: ""})
        }
    }

    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});

    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }

    //选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }

    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }


    const [user, setUser] = useState<any>()
    const [pickUserModalOpen, setPickUserModalOpen] = useState(false);
    const handleCancelUserModal = () => {
        setPickUserModalOpen(false);
    }

    const confirmUser = (e: any) => {
        setUser(e);
        if (type === 1) {
            form.setFieldsValue({"backId": e?.userId})
        } else {
            form.setFieldsValue({"organizeId": e?.userId})
        }
    }

    return (
        <Drawer width={'100vw'} open={open} onClose={onCancel} destroyOnClose
                title={`${type === 1 ? "隐患排查任务新增" : "演练学习任务新增"}`}
                extra={
                    <Space>
                        <Button loading={loading} style={{width: '200px'}} type="primary"
                                onClick={debounce(handleAddNewTask, 1000)}>
                            确认提交
                        </Button>
                    </Space>
                }
        >
            <Form form={form} name="form" labelAlign="right" labelCol={{
                xs: {span: 24},
                sm: {span: 6},
            }} wrapperCol={{
                xs: {span: 24},
                sm: {span: 14},
            }}>
                <Form.Item
                    name="taskName"
                    label="任务名称"
                    rules={[
                        {
                            required: true, message: "请输入任务名称"
                        },
                    ]}
                >
                    <Input placeholder="请输入任务名称"/>
                </Form.Item>

                <Form.Item
                    name="taskTime"
                    label="任务起止时间"
                    rules={[
                        {
                            required: true, message: "请选择起止时间"
                        },
                    ]}
                >
                    <DatePicker.RangePicker style={{width: "100%"}} showTime={{format: 'HH'}}
                                            format="YYYY-MM-DD HH:00"
                                            placeholder={['开始时间', '结束时间']}
                                            onChange={handleTaskTimeChange}/>
                </Form.Item>


                <Form.Item
                    name="communityName"
                    label="任务地点"
                    rules={[
                        {
                            required: true, message: "请输入任务地点"
                        },
                    ]}
                >
                    <Input placeholder="请输入任务地点"/>
                </Form.Item>

                <Form.Item
                    name="location"
                    label="定位"
                    rules={[
                        {
                            required: true, message: "请选择定位"
                        },
                    ]}
                >
                    {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{color: "#235c7a"}}>
                                <div>经度:{loc?.lng}</div>
                                <div>纬度:{loc?.lat}</div>
                            </div>
                            <div style={{width: "20px"}}/>
                            <div style={{display: "grid", placeItems: "center"}}>
                                <Button type="primary" onClick={() => {
                                    setPickLocModalOpen(true)
                                }}>重新选择
                                </Button>
                            </div>

                        </div>
                    ) : (
                        <Button type="primary" onClick={() => {
                            getLocation();
                            setPickLocModalOpen(true);
                        }}>选择定位
                        </Button>
                    )}
                </Form.Item>


                {type === 1 ? (
                    <Form.Item
                        name="backId"
                        label="检查人员"
                        rules={[
                            {
                                required: true, message: "请输入检查人员"
                            },
                        ]}
                    >
                        {user ? (<div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                                <div style={{color: "#235c7a"}}>
                                    <div> {user?.username}{user?.mobile}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickUserModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                setPickUserModalOpen(true);
                            }}>选择负责人
                            </Button>
                        )}
                    </Form.Item>
                ) : (
                    <Form.Item
                        name="organizeId"
                        label="上报人员"
                        rules={[
                            {
                                required: true, message: "请选择上报人员"
                            },
                        ]}
                    >
                        {user ? (<div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                                <div style={{color: "#235c7a"}}>
                                    <div> {user?.username}{user?.mobile}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickUserModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                setPickUserModalOpen(true);
                            }}>选择负责人
                            </Button>
                        )}
                    </Form.Item>
                )}


                {/*<Form.Item*/}
                {/*    name="communityIds"*/}
                {/*    label={`${type === 1 ? "隐患排查" : "演练学习"}对象`}*/}
                {/*>*/}
                {/*    <Button type={'default'} style={{width: '200px'}} onClick={() => {*/}
                {/*        setAddObjectModal(true);*/}
                {/*    }}>添加</Button>*/}

                {/*    {objectList?.length > 0 && (*/}
                {/*        <Table pagination={{pageSize: 5}} style={{marginTop: '15px'}} rowKey={'hydrantId'}*/}
                {/*               columns={columns} dataSource={objectList}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*</Form.Item>*/}
            </Form>
            <AddTaskCommunitiesModal open={addObjectModal}
                                     onCancel={handleCloseAddObjectModal}
                                     insertRecords={handleInsertObjectList}
                                     initObjectList={objectList}

            />

            <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                               confirmLoc={(e: any) => {
                                   confirmLoc(e)
                               }} confirmName={() => {
            }} confirmAddress={() => {
            }}
                               confirmRoad={() => {
                               }} repick={undefined}/>

            <PickUser open={pickUserModalOpen} onCancel={handleCancelUserModal} confirmUser={confirmUser}
                      initUser={user} type={1}/>
        </Drawer>
    );
};

export default AddExerciseTaskDrawer;