import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    deleteRoad,
    getAllRoad,
    getAreaTree,
    getRoads
} from "../../../api";
import {getAreaPath, showFail, showSuccess, transform} from "../../../components/functions";
import AddRoadModal from "../../../components/addRoadModal";
// @ts-ignore
import {debounce} from "lodash";
import {useLocation} from "react-router-dom";

const Hydrant = () => {
        const location = useLocation();
        let oriQrCode = location?.state?.qrCode;
        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)

        const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        let params: any = {}
        const handleGetRoadList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            if (searchCondition?.areaId) {
                params.areaId = searchCondition?.areaId
            }
            if (searchCondition?.road) {
                params.roadName = searchCondition?.road
            }
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            getAllRoad(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setHydrantList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetRoadList(pageNum, pageSize);
        }, [pageSize, pageNum])

        const [areaTree, setAreaTree] = useState<any>();
        const [areaTreeTran, setAreaTreeTran] = useState<any>();
        const handleGetArea = () => {
            getAreaTree().then((res) => {
                setAreaTree(res?.data);
                let areaTreeTran = transform(res?.data);
                console.log('areaTreeTran', areaTreeTran);
                let _nthAreaTreeTran = areaTreeTran?.children?.find((d: any) => (d?.value === '4'))
                console.log('_nthAreaTreeTran', _nthAreaTreeTran);
                // setAreaTreeTran(transform(res?.data))
                setAreaTreeTran(_nthAreaTreeTran)
            }).catch((err) => {
                console.log(err)
            })
        }

        const [roads, setRoads] = useState([])

        const handleGetRoads = () => {
            getRoads().then((res: any) => {
                const items = res?.data;
                const roadNames = new Set();
                const uniqueItems = items.filter((item: any) => {
                    if (roadNames.has(item.roadName)) {
                        return false;
                    } else {
                        roadNames.add(item.roadName);
                        return true;
                    }
                });
                setRoads(uniqueItems?.map((d: any) => {
                    return {value: d?.roadName, label: d?.roadName}
                }))
            }).catch((err: any) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetArea();
            handleGetRoads();
        }, [])

        const [searchCondition, setSearchCondition] = useState({
            hydrantId: "",
            areaId: "",
            hydrantName: "",
            hydrantAddress: "",
            qrCode: oriQrCode || "",
            road: "",
            hydrantState: "",
            appearanceOk: "",
            hasWater: "",
            pressureOk: ""
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);


        const handleAreaChange = (v: any) => {
            if (v) {
                setSearchCondition({...searchCondition, areaId: v[v?.length - 1]})
            } else {
                setSearchCondition({...searchCondition,areaId: ""})
            }
        }

        const handleRoadChange = (v: any) => {
            setSearchCondition({
                ...searchCondition,
                road: v
            })
        }

        const [roadOptions, setRoadOptions] = useState<any[]>([])
        const getPanelValue = (searchText: string) =>
            !searchText ? [] : roads?.filter((r: any) => {
                return r?.value?.includes(searchText)
            })



        const columns: any = [
            {
                title: '县区及街道',
                dataIndex: 'areaId',
                key: 'areaId',
                render: (areaId: string) =>
                    (<div>{areaTree ? getAreaPath(areaTree, areaId) : "数据加载中"}</div>)
            },
            {
                title: '道路',
                dataIndex: 'roadName',
                key: 'road'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: any) => (
                    <div>
                        <Popconfirm title={"是否确认删除该道路"} onConfirm={() => {
                            deleteRoad({roadId: record?.roadId}).then((res: any) => {
                                if (res?.code === 200) {
                                    handleGetRoadList(pageNum, pageSize, newSearchCondition).then(() => {
                                        showSuccess(res?.msg);
                                    })
                                } else {
                                    showFail(res?.msg)
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Button danger type={'link'} color={'danger'}>删除</Button>
                        </Popconfirm>
                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }






        const [rectifyFormOpen, setRectifyFormOpen] = useState<boolean>(false);



        return (
            <div className={styles.main}>

                <div className={styles.mainHead}>


                        <Cascader onChange={handleAreaChange} style={{width: 250, marginRight: '30px', marginTop: '10px'}}
                                  options={areaTreeTran ? [areaTreeTran] : []}
                                  changeOnSelect placeholder="县区/街道/乡镇" allowClear/>

                        <AutoComplete
                            options={roadOptions}
                            style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            onSearch={(text) => setRoadOptions(getPanelValue(text))}
                            placeholder="道路"
                            allowClear
                            onChange={handleRoadChange}
                        />

                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetRoadList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                            setAddModalVisible(true);
                        }}>新增
                        </Button>

                    </div>

                </div>

                <Table rowKey={'areaId'} columns={columns} dataSource={hydrantList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>


                <AddRoadModal open={addModalVisible} onClose={handleCloseAddModal} areaOptions={[areaTreeTran]}
                                 handleGetHydrant={async () => {
                                     await handleGetRoadList(pageNum, pageSize, newSearchCondition)
                                 }}/>






            </div>
        );
    }
;

export default Hydrant;