import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Select} from "antd";
import {Map, Marker} from "react-amap";
// @ts-ignore
import {debounce} from "lodash"
import {getAreaIdArray, showFail, showNeedCompleteInfo, showNetError, showSuccess} from "../functions";
import {updateContact} from "../../api";
import {CommunityContact} from "../../interface";

const EditContactModal = ({
                              open,
                              onCancel,
                              communityContact,
                              handleGetCommunity
                          }: { open: boolean, onCancel: () => void, communityContact: CommunityContact, handleGetCommunity?: any }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (open) {
            form.resetFields();
        }
    }, [open])
    const handleUpdate = () => {
        form.validateFields()
            .then((value) => {
                if (!value?.contactLandlinePhone && !value?.contactMobile) {
                    showFail("请至少输入手机号和固话其中的一项!")
                } else {
                    updateContact({...value,
                    communityContactId:communityContact?.communityContactId
                    })
                        .then((res: any) => {
                            if (res?.code === 200) {
                                handleGetCommunity().then(() => {
                                    onCancel();
                                    form.resetFields();
                                    showSuccess(res?.msg)
                                });
                            } else {
                                showFail(res?.msg)
                            }
                        })
                        .catch((err: any) => {
                            console.log(err);
                            showNetError()
                        })
                }
            })
            .catch((err: any) => {
                console.log(err);
                showNeedCompleteInfo();
            })
    }

    return (
        <Modal open={open} onCancel={() => {
            onCancel();
        }} onOk={debounce(handleUpdate, 500)} destroyOnClose okText={'确认修改'} title={'联系人修改'}>
            <Form form={form} name="form" labelAlign="right" labelCol={{
                xs: {span: 24},
                sm: {span: 6},
            }} wrapperCol={{
                xs: {span: 24},
                sm: {span: 14},
            }}>
                <Form.Item
                    name="contactType"
                    label="类型"
                    initialValue={communityContact?.contactType}
                    rules={[
                        {
                            required: true, message: "请选择联系人类型"
                        },
                    ]}
                >
                    <Select options={[
                        {value: "社区联系人", label: "社区联系人"},
                        {value: "街道联系人", label: "街道联系人"},
                        {value: "村部联系人", label: "村部联系人"},
                        {value: "网格员联系人", label: "网格员联系人"},
                    ]}/>
                </Form.Item>

                <Form.Item
                    name="contactName"
                    label="名称"
                    initialValue={communityContact?.contactName}
                >
                    <Input placeholder={'请输入联系人名称'}/>
                </Form.Item>

                <Form.Item
                    name="contactMobile"
                    label="手机号"
                    initialValue={communityContact?.contactMobile}
                >
                    <Input placeholder={'请输入联系人手机号'}/>
                </Form.Item>

                <Form.Item
                    name="contactLandlinePhone"
                    label="固话"
                    initialValue={communityContact?.contactLandlinePhone}
                >
                    <Input placeholder={'请输入固话'}/>
                </Form.Item>

                <Form.Item
                    name="contactRemark"
                    label="备注"
                    initialValue={communityContact?.contactRemark}
                >
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditContactModal;