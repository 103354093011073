import React, {useEffect, useState} from "react";
import {iHydrant} from "../../interface";
import {getAreaTree, getHydrantPage, getRoads} from "../../api";
import {diffArray, getAreaPath, showFail, showSuccess, transform} from "../functions";
import locationIcon from "../../assets/icons/location.svg";
import {AutoComplete, Button, Cascader, Input, Modal, Table, Tooltip} from "antd";
import noneIcon from "../../assets/icons/none.svg";
import styles from "../taskRecordDrawer/index.module.css";
import LocationModal from "../locationModal";

const AddInspectRecordModal = ({
                                   open,
                                   onCancel,
                                   insertRecords,
                                   initHydrantList
                               }: { open: boolean, onCancel: () => void, insertRecords: any, initHydrantList: iHydrant[] | [] }) => {
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [hydrantList, setHydrantList] = useState<iHydrant[]>([])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    let params: any = {}
    const handleGetHydrantList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.hydrantName) {
            params.hydrantName = searchCondition?.hydrantName
        }
        if (searchCondition?.hydrantAddress) {
            params.hydrantAddress = searchCondition?.hydrantAddress
        }
        if (searchCondition?.areaId) {
            params.areaId = searchCondition?.areaId
        }
        if (searchCondition?.qrCode) {
            params.qrCode = searchCondition?.qrCode
        }
        if (searchCondition?.road) {
            params.road = searchCondition?.road
        }
        getHydrantPage(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setHydrantList(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (open) {
            handleGetHydrantList(pageNum, pageSize, newSearchCondition);
        }
    }, [pageSize, pageNum, open])

    const [areaTree, setAreaTree] = useState<any>();
    const [areaTreeTran, setAreaTreeTran] = useState<any>();
    const handleGetArea = () => {
        getAreaTree().then((res) => {
            setAreaTree(res?.data);
            setAreaTreeTran(transform(res?.data))
        }).catch((err) => {
            console.log(err)
        })
    }

    const [initHydrantIdList, setInitHydrantIdList] = useState<string[] | []>([]);
    const [roads, setRoads] = useState([])

    const handleGetRoads = () => {
        getRoads().then((res: any) => {
            const items = res?.data;
            const roadNames = new Set();
            const uniqueItems = items.filter((item: any) => {
                if (roadNames.has(item.roadName)) {
                    return false;
                } else {
                    roadNames.add(item.roadName);
                    return true;
                }
            });
            setRoads(uniqueItems?.map((d: any) => {
                return {value: d?.roadName, label: d?.roadName}
            }))
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const [roadOptions, setRoadOptions] = useState<any[]>([])
    const getPanelValue = (searchText: string) =>
        !searchText ? [] : roads?.filter((r: any) => {
            return r?.value?.includes(searchText)
        })

    useEffect(() => {
        if (open) {
            handleGetArea();
            let _initIdList = initHydrantList?.map((h) => (h?.hydrantId)) as string[];
            setInitHydrantIdList(_initIdList);
            setSelectedRowKeys(_initIdList);
            handleGetRoads();

        }
    }, [open])

    const [searchCondition, setSearchCondition] = useState({
        id: "",
        areaId: "",
        hydrantName: "",
        hydrantAddress: "",
        qrCode: "",
        road: ""
    });
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);


    const handleNameChange = (e: any) => {
        setSearchCondition({...searchCondition, hydrantName: e?.target?.value})
    }

    const handleAddressChange = (e: any) => {
        setSearchCondition({...searchCondition, hydrantAddress: e?.target?.value})
    }

    const handleQrcodeChange = (e: any) => {
        setSearchCondition({...searchCondition, qrCode: e?.target?.value})
    }

    const handleRoadChange = (e: any) => {
        setSearchCondition({...searchCondition, road: e})
    }

    const handleAreaChange = (v: any) => {
        if (v) {
            setSearchCondition({...searchCondition, areaId: v[v?.length - 1]})
        } else {
            setSearchCondition({...searchCondition, areaId: ""})
        }
    }

    //位置对话框
    const [hydrantLng, setHydrantLng] = useState<string | number>();
    const [hydrantLat, setHydrantLat] = useState();
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const handleCloseLocationModal = () => {
        setLocationModalOpen(false);
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | []>([]);
    const [selectedRows, setSelectedRows] = useState(initHydrantList);
    const onSelectChange = (newSelectedRowKeys: any, selectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(selectedRows);
    };
    let finalSelected = diffArray(selectedRowKeys, initHydrantIdList);
    const hasSelected = finalSelected.length > 0;

    let finalSelectedRows = selectedRows.filter((item) => finalSelected.includes(item?.hydrantId as string))

    const rowSelection: any = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
        type: 'checkbox',
        getCheckboxProps: (record: any) => {
            let disabled: boolean = false;
            let _init = initHydrantIdList?.map((s: string) => (Number(s)));
            if (_init.includes(Number(record?.hydrantId))) {
                disabled = true
            }
            return {
                ...record,
                disabled
            }
        },
    };


    const columns: any = [
        {
            title: '名称',
            dataIndex: 'hydrantName',
            key: 'hydrantName',
        },
        {
            title: '县区及街道',
            dataIndex: 'areaId',
            key: 'areaId',
            render: (areaId: string) =>
                (<div>{areaTree ? getAreaPath(areaTree, areaId) : "数据加载中"}</div>)
        },
        {
            title: '道路',
            dataIndex: 'road',
            key: 'road'
        },
        {
            title: '地址',
            dataIndex: 'hydrantAddress',
            key: 'hydrantAddress'
        },
        {
            title: '二维码',
            dataIndex: 'qrCode',
            key: 'qrCode',
        },
        {
            title: '定位',
            dataIndex: 'location',
            key: 'location',
            render: (_: any, record: any) => (
                <>
                    {(record?.lng && record?.lat) ? (
                        <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                             onClick={() => {
                                 setLocationModalOpen(true);
                                 setHydrantLat(record?.lat);
                                 setHydrantLng(record?.lng);
                             }}/>
                    ) : (<div>
                        <Tooltip title={'该XHS暂无定位'}>
                            <img src={noneIcon} alt="" width={20} height={20}/>
                        </Tooltip>
                    </div>)}
                </>
            )
        },
    ];


    return <Modal open={open} onCancel={onCancel} title={'新增待巡检XHS'} width={'60vw'}
                  okText={'添加'} onOk={() => {
        insertRecords(finalSelectedRows)
    }}>
        <div className={styles.mainHead}>
            <div className={styles.searchContent}>
                <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                       onChange={handleNameChange} placeholder={'XHS名称'} allowClear/>

                <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                       onChange={handleAddressChange} placeholder={'XHS地址'} allowClear/>

                <AutoComplete
                    options={roadOptions}
                    style={{width: 200, marginRight: 30, marginTop: '10px'}}
                    onSearch={(text) => setRoadOptions(getPanelValue(text))}
                    placeholder="道路"
                    allowClear
                    onChange={handleRoadChange}
                />

                <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                       onChange={handleQrcodeChange} placeholder={'XHS二维码'} allowClear/>

                <Cascader onChange={handleAreaChange} style={{width: 250, marginTop: '10px'}}
                          options={areaTreeTran ? [areaTreeTran] : []}
                          changeOnSelect placeholder="县区/街道/乡镇"/>

            </div>
            <div className={styles.searchButton}>
                <Button style={{marginRight: '20px'}} type='primary' onClick={() => {
                    handleGetHydrantList(1, 5, searchCondition);
                    setPageNum(1);
                    setPageSize(5);
                    setNewSearchCondition(searchCondition)
                }}>查询
                </Button>

            </div>
        </div>


        <div style={{height: "1vh"}}/>
        {hasSelected && (<div style={{display: 'flex', flexDirection: 'row', fontSize: '15px'}}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row'
                }}>已选中 {finalSelected.length} 个XHS
            </div>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                <Button style={{fontSize: '15px'}} type='link' onClick={() => {
                    setSelectedRowKeys(initHydrantIdList)
                }}>
                    取消勾选
                </Button>
            </div>
        </div>)}

        <Table rowKey={'hydrantId'} columns={columns} dataSource={hydrantList} loading={loading}
               rowSelection={rowSelection}
               pagination={{
                   current: pageNum,
                   onChange: onPageChange,
                   onShowSizeChange: onPageSizeChange,
                   total: total,
                   showSizeChanger: true,
                   defaultPageSize: pageSize,
                   pageSizeOptions: ['5', '10', '20', '50', '100', '200', '500'],
               }}/>
        <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                       lat={hydrantLat} lng={hydrantLng}/>
    </Modal>
}

export default AddInspectRecordModal;