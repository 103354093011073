import {
    Button,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber, message,
    Modal,
    Select,
    Space,
    Table,
    Tooltip
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showSuccess} from "../functions";
import {addRegularInspectPlan, getUserList} from "../../api";
import {RangePickerProps} from 'antd/es/date-picker';
import AddInspectRecordModal from "../addInspectRecord";
import {iHydrant} from "../../interface";
import {ExclamationCircleFilled} from '@ant-design/icons';
import moment from "moment";

const AddRegularInspectPlanModal = ({
                                        open,
                                        onClose,
                                        handleGetRegularInspectPlan
                                    }: { open: boolean, onClose: () => void, handleGetRegularInspectPlan: any }) => {
    const [form] = Form.useForm();
    const [userOptions, setUserOptions] = useState<any[]>([]);
    const changeName = (e: any) => {
        let username = e.target.value
        if (username) {
            getUserList({username}).then((res: any) => {
                let _user = res?.data?.map((d: any) => {
                    return {
                        label: `姓名:${d?.username}  手机号:${d?.mobile}`,
                        value: d?.userId
                    }
                })
                setUserOptions(_user)
            }).catch((err: any) => {
                console.log(err);
            })
        }
    }

    const handlePlanStartTimeChange = (date: any) => {
        if (date) {
            let startTime = date?.$d?.valueOf();
            const now = new Date();
            const nextHour = new Date(now.getTime() + 60 * 60 * 1000);
            if (startTime > nextHour) {
                form.setFieldsValue({regularStartTime: date})
            } else {
                let nextHourStr = moment(nextHour).format('YYYY-MM-DD HH:mm:ss')
                message.warning({content: `首次任务开始时间必须晚于${nextHourStr}(当前时间的后一小时)`, duration: 2.5})
                form.setFieldsValue({regularStartTime: ""})
            }
        } else {
            form.setFieldsValue({regularStartTime: ""})
        }
    }

    const [cycle, setCycle] = useState<number | null>();
    const handleCycleChange = (n: number | null) => {
        if (n) {
            if (executionDay) {
                if (executionDay > n) {
                    setCycle(null);
                    form.setFieldsValue({cycle: null})
                    message.warning({
                        content: `计划周期不能小于任务持续时间(${executionDay}天)`,
                        duration: 2.5
                    })
                } else {
                    setCycle(n);
                    form.setFieldsValue({cycle: n})
                }
            } else {
                setCycle(n);
                form.setFieldsValue({cycle: n})
            }
        } else {
            setCycle(null);
            form.setFieldsValue({cycle: null})
        }

    }

    const [executionDay, setExecutionDay] = useState<number | null>();
    const handleExecutionDayChange = (n: number | null) => {
        if (n) {
            if (cycle) {
                if (cycle < n) {
                    setExecutionDay(null);
                    form.setFieldsValue({executionDay: null})
                    message.warning({
                        content: `任务持续时间不能大于计划周期(${cycle}天)`,
                        duration: 2.5
                    })
                } else {
                    setExecutionDay(n);
                    form.setFieldsValue({executionDay: n})
                }
            } else {
                setExecutionDay(n);
                form.setFieldsValue({executionDay: n})
            }
        } else {
            setExecutionDay(null);
            form.setFieldsValue({executionDay: null})
        }
    }


    const [loading, setLoading] = useState<boolean>(false);

    const pushData = (values: any) => {
        setLoading(true);
        addRegularInspectPlan(values).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                handleGetRegularInspectPlan().then(() => {
                    showSuccess(res?.msg);
                    onClose();
                    form.resetFields();
                    setUserOptions([]);
                })
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const handleAddNewRegularInspectPlan = () => {
        form.validateFields().then((value) => {
            let newInspectPlan = {
                ...value,
                regularStartTime: value?.regularStartTime?.$d.valueOf(),
            }
            if (hydrantList?.length > 0) {
                let _newInspectPlan = {
                    ...newInspectPlan,
                    regularHydrantList: hydrantList?.map((i) => {
                        return {
                            hydrantId: i?.hydrantId,
                            userId: i?.userId,
                        }
                    })
                }
                pushData(_newInspectPlan);
            } else {
                Modal.confirm({
                    title: '提示',
                    icon: <ExclamationCircleFilled/>,
                    content: '当前巡检清单为空, 是否继续提交该计划?',
                    onOk() {
                        pushData(newInspectPlan);
                    },
                });
            }


        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }

    //巡检清单记录新增
    const [addRecordModalVisible, setAddRecordModalVisible] = useState<boolean>(false);
    const handleCloseAddRecordModal = () => {
        setAddRecordModalVisible(false)
    }

    const [hydrantList, setHydrantList] = useState<iHydrant[] | []>([])
    const handleInsertHydrantList = (records: iHydrant[]) => {
        setHydrantList((prevState: any) => {
            return [...prevState, ...records];
        });
        setAddRecordModalVisible(false);
        showSuccess('添加成功')
    }


    const columns: any = [
        {
            title: '名称',
            dataIndex: 'hydrantName',
            key: 'hydrantName',
        },
        {
            title: '地址',
            dataIndex: 'hydrantAddress',
            key: 'hydrantAddress'
        },
        {
            title: '二维码',
            dataIndex: 'qrCode',
            key: 'qrCode',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (_: any, {hydrantId}: any) => (
                <Button type={'link'} danger onClick={() => {
                    setHydrantList((pre: any) => {
                        let remainArr = pre?.filter((a: any) => a?.hydrantId !== hydrantId);
                        return remainArr
                    })
                }}>删除</Button>
            )
        },
    ];


    return (
        <div>
            <Drawer width={'100vw'} open={open} onClose={() => {
                onClose();
                setLoading(false);
                setUserOptions([])
            }} title={'定期计划新增'} maskClosable={false} extra={
                <Space>
                    <Button loading={loading} style={{width: '200px'}} type="primary"
                            onClick={debounce(handleAddNewRegularInspectPlan, 100)}>
                        确认提交
                    </Button>
                </Space>
            }>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="name"
                        label="计划名称"
                        rules={[
                            {
                                required: true, message: "请输入计划名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入计划名称"/>
                    </Form.Item>

                    <Form.Item
                        name="cycle"
                        label="周期(天)"
                        rules={[
                            {
                                required: true, message: "请输入计划执行周期"
                            },
                        ]}
                    >
                        <InputNumber addonAfter="天" min={0} placeholder="请输入计划执行周期" style={{width: "300px"}}
                                     onChange={ debounce(handleCycleChange, 1000)}/>
                    </Form.Item>

                    <Form.Item
                        name="regularStartTime"
                        label="首次任务开始时间"
                        rules={[
                            {
                                required: true, message: "请选择首次任务开始时间"
                            },
                        ]}
                    >
                        <DatePicker placeholder={'请选择首次任务开始时间'} style={{width: "100%"}} showTime={{format: 'HH'}}
                                    format="YYYY-MM-DD HH:00" onChange={handlePlanStartTimeChange}
                        />
                    </Form.Item>

                    <Form.Item
                        name="executionDay"
                        label="任务持续时间(天)"
                        rules={[
                            {
                                required: true, message: "请输入巡检任务的执行时间(天)"
                            },
                        ]}
                    >
                        <InputNumber addonAfter="天" min={0} onChange={debounce(handleExecutionDayChange, 1000)}
                                     placeholder="请输入一次巡检任务的执行时间" style={{width: "300px"}}/>
                    </Form.Item>

                    <Form.Item
                        name="userId"
                        label="负责人"
                        rules={[
                            {
                                required: true, message: "请选择任务负责人"
                            },
                        ]}
                    >
                        <Select
                            allowClear={true}
                            placeholder={"请输入用户名查询并选择"}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0'}}/>
                                    <Space style={{padding: '4px 8px 4px '}}>
                                        <Input
                                            allowClear
                                            placeholder="请输入用户名"
                                            onChange={debounce(changeName, 600)}
                                        />

                                    </Space>
                                </>
                            )}
                            options={userOptions}
                        />
                    </Form.Item>


                    <Form.Item
                        name="taskRemark"
                        label="备注"
                    >
                        <Input placeholder="请输入任务备注"/>
                    </Form.Item>


                    <Form.Item
                        name="inspectionRecords"
                        label="巡检清单"
                    >
                        <Button type={'default'} style={{width: '200px'}} onClick={() => {
                            setAddRecordModalVisible(true);
                        }}>添加</Button>

                        {hydrantList?.length > 0 && (
                            <Table pagination={{pageSize: 5}} style={{marginTop: '15px'}} rowKey={'hydrantId'}
                                   columns={columns} dataSource={hydrantList}
                            />
                        )}

                    </Form.Item>
                </Form>

                <AddInspectRecordModal open={addRecordModalVisible} onCancel={handleCloseAddRecordModal}
                                       insertRecords={handleInsertHydrantList}
                                       initHydrantList={hydrantList}
                />
            </Drawer>
        </div>
    );
};

export default AddRegularInspectPlanModal;