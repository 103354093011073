import React from 'react';
import {Avatar, Badge, Dropdown, Menu} from "antd";
import {useNavigate} from "react-router-dom";

const AvatarDropDown = () => {
    const navigate = useNavigate();
    let sysUser=JSON.parse(window.localStorage.getItem("sysUser") as string);
    let name = sysUser?.username;
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <a target="_blank" style={{color: '#151dbb', fontSize: 15}} href={"/#/personal"}>
                            个人中心
                        </a>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <div style={{color: '#151dbb', fontSize: 15}} onClick={() => {
                            window.localStorage.clear();
                            navigate("/login", {replace: true});
                        }}>
                            {name ? "退出登录" : "登录"}
                        </div>
                    ),
                }
            ]}
        />
    );

    return (
        <Dropdown overlay={menu} placement="bottomLeft" arrow={{pointAtCenter: true}}>
            <Avatar style={{backgroundColor: "#6083d0", verticalAlign: 'middle'}} size="large">
                <div>{name?.substring(name?.length - 2) || "?"}</div>
            </Avatar>
        </Dropdown>
    );
};

export default AvatarDropDown;