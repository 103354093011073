import axios from 'axios';
import {showNetError} from "../components/functions";
const Axios = axios.create();

Axios.defaults.timeout = 200000;
Axios.defaults.timeout = 200000;
// console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') {
    // Axios.defaults.baseURL = "https://xfapi-dev.iterated.xyz/api";
    // Axios.defaults.baseURL = "http://localhost:8006/api";
    Axios.defaults.baseURL = "https://www.huxf.com.cn/api";
} else {
    // Axios.defaults.baseURL = "https://xfapi-dev.iterated.xyz/api";
    Axios.defaults.baseURL = "https://www.huxf.com.cn/api";
}


Axios.defaults.responseType = "json";

Axios.interceptors.request.use(
    (config) => {
        if (config.url.match(/[^login|sendSMS|feature]/)){
            if (window.localStorage.getItem('token')){
                config.headers.Authorization = window.localStorage.getItem('token');
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
Axios.interceptors.response.use(
    (res) => {
        if (res.status >= 200 && res.status < 300){
            if (res?.data?.code===401||res?.data?.code===403) {
                window.localStorage.clear();
                setTimeout(()=>{
                    // window.location.href="/"
                    window.location.href = '/#/login';
                },1500)
            }
            return res.data;
        }else {

        };
        return res;
    },
    (err) => {
        // console.log('err', err);
        if (err.response.status===403){
            alert('登陆信息过期或无效，请重新登陆');
            window.localStorage.clear();
            // window.location.href = '/';
        }
        showNetError();
        return Promise.reject(err);
    }
)

export default Axios;