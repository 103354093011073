import React, {useEffect, useState} from 'react';
import {Button, Divider, Drawer, Image, Input, Modal, Select, Space, Table, Tag} from "antd";
import {iHydrant} from "../../interface";
import {getHydrantList, getInspectRecordPage} from "../../api";
import styles from "./index.module.css";
import {showFail} from "../functions";


const HydrantInspectRecordDrawer = ({
                                        open,
                                        onClose,
                                        hydrant
                                    }: { open: boolean, onClose: () => void, hydrant: iHydrant }) => {
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const onPageChange = (_page: number) => {
        setPageNum(_page);
        handleGetInspectRecords(_page, pageSize, newSearchCondition)
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
        handleGetInspectRecords(pageNum, _pageSize, newSearchCondition)
    };
    const [searchCondition, setSearchCondition] = useState<any>({
        recordState: ""
    });

    const handleRecordStateChange = (v: any) => {
        setSearchCondition({
            ...searchCondition,
            recordState: v
        })
    }


    const [newSearchCondition, setNewSearchCondition] = useState<any>();
    let hydrantId = hydrant?.hydrantId;
    const [loading, setLoading] = useState<boolean>(false);
    const [inspectRecords, setInspectRecords] = useState();
    const handleGetInspectRecords = (pageNum: number, pageSize: number, searchCondition: any) => {
        let params: any = {}
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        params.hydrantId = hydrantId;
        if (searchCondition?.recordState !== "") {
            params.recordState = searchCondition?.recordState
        }
        setLoading(true)
        getInspectRecordPage(params).then((res: any) => {
            console.log(res);
            setLoading(false)
            if (res?.code === 200) {
                setInspectRecords(res?.data?.records);
                setTotal(Number(res?.data?.total));
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (open) {
            handleGetInspectRecords(pageNum, pageSize, newSearchCondition);
        }
    }, [open])

    const columns: any = [
        {
            title: '所属任务',
            dataIndex: 'inspectionTask',
            key: 'inspectionTask',
            render: (inspectionTask: any) => (
                <div>
                    {inspectionTask?.taskName}
                </div>
            )
        },
        {
            title: '巡检状态',
            dataIndex: 'recordState',
            key: 'recordState',
            render: (recordState: any) => (
                <div>
                    {recordState === 0 ? <Tag color={'blue'}>未发布</Tag> :
                        recordState === 1 ? <Tag color={'orange'}>巡检中</Tag> :
                            <Tag color={'green'}>巡检完成</Tag>}
                </div>
            )
        },
        {
            title: '巡检人',
            dataIndex: 'sysUser',
            key: 'sysUser',
            render: (sysUser: any) => (
                <div>
                    {sysUser?.username} {sysUser?.mobile}
                </div>
            )
        },
        {
            title: '完成时间',
            dataIndex: 'recordCompleteTime',
            key: 'recordCompleteTime'
        },
        {
            title: '巡检详情',
            dataIndex: 'detail',
            key: 'detail',
            render: (_: any, record: any) => (
                <>
                    {
                        record?.recordState === 2 && (
                            <Button type={'link'} onClick={() => {
                                setInspectRecord(record)
                                setDetailModalVisible(true)
                            }}>查看</Button>
                        )
                    }
                </>

            )
        }
    ]

    const [inspectRecord, setInspectRecord] = useState<any>()
    const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
    const handleCloseDetailModal = () => {
        setDetailModalVisible(false);
    }
    const DetailModal = ({open, onClose, record}: { open: boolean, onClose: () => void, record: any }) => {
        const [imageVisible, setImageVisible] = useState<boolean>(false);
        const [hydrantPictures, setHydrantPictures] = useState<[]>([]);
        return (
            <>
                <Image.PreviewGroup
                    items={hydrantPictures}
                    preview={{
                        visible: imageVisible,
                        onVisibleChange: (value) => {
                            setImageVisible(value);
                        },
                    }}
                >
                    {hydrantPictures.map((image: any) => (
                        <Image
                            key={image.key}
                            width={200}
                            height={200}
                            style={{display: 'none'}}
                            src={image.src}
                            preview={{
                                scaleStep: 0.5,
                                src: image.src,
                            }}
                        />
                    ))}
                </Image.PreviewGroup>
                <Modal open={open} onCancel={onClose} okButtonProps={{style: {display: 'none'}}}
                       title={'巡检记录详情'} cancelText={'知道了'} cancelButtonProps={{type:'primary'}}
                >
                    <div>
                        {record?.isDanger === 1 ? (
                            <>
                                <div className={styles.row}>
                                    <div className={styles.title}>外观</div>
                                    <div className={styles.content}>{record?.appearanceOk ? (<div style={{color: 'green'}}>
                                        完好
                                    </div>) : (<div style={{color: 'red'}}>
                                        有损坏
                                    </div>)}</div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.title}>出水情况</div>
                                    <div className={styles.content}>{record?.hasWater ? (<div style={{color: 'green'}}>
                                        有水
                                    </div>) : (<div style={{color: 'red'}}>
                                        不出水
                                    </div>)}</div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.title}>水压</div>
                                    <div className={styles.content}>{record?.pressureOk ? (<div style={{color: 'green'}}>
                                        正常
                                    </div>) : (<div style={{color: 'red'}}>
                                        水压不足
                                    </div>)}</div>
                                </div>

                                {record?.recordRemark && (
                                    <div className={styles.row}>
                                        <div className={styles.title}>描述</div>
                                        <div className={styles.content}>{inspectRecord?.recordRemark}</div>
                                    </div>
                                )}

                                <div className={styles.row} style={{alignItems: 'center'}}>
                                    <div className={styles.title}>照片</div>
                                    <div className={styles.content}>
                                        <Button type={'link'} onClick={() => {
                                            getHydrantList({hydrantId: inspectRecord?.hydrantId}).then((res: any) => {
                                                if(res?.code===200){
                                                    setHydrantPictures(res?.data[0]?.pictures?.map((u: any) => {
                                                        return {
                                                            key: u?.pictureId,
                                                            src: u?.url
                                                        }
                                                    }));
                                                }else{
                                                    showFail(res?.msg)
                                                }
                                            })
                                            setImageVisible(true)
                                        }}>查看</Button>
                                    </div>
                                </div>
                            </>
                        ) : (<div style={{display: 'grid',
                            placeItems: 'center',
                            fontWeight: 'bold',
                            fontSize:'18px',
                            color: '#729678'}}>
                            XHS一切正常
                        </div>)}

                    </div>
                </Modal>
            </>
       )
    }

    return (
        <Drawer width={'50vw'} open={open} title={`${hydrant?.hydrantName}巡检记录`} onClose={onClose}
        >
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            options={[
                                {value: 0, label: '未发布'},
                                {value: 1, label: '待巡检'},
                                {value: 2, label: '已巡检'},
                            ]} placeholder={'巡检状态'} allowClear onChange={handleRecordStateChange}/>
                </div>
                <div className={styles.searchButton}>
                    <Button style={{marginRight: '20px'}} type='primary' onClick={() => {
                        handleGetInspectRecords(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>
                </div>

            </div>
            <div style={{height: "1vh"}}/>

            <Table rowKey={'recordId'} columns={columns} dataSource={inspectRecords} loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            <DetailModal open={detailModalVisible} onClose={handleCloseDetailModal} record={inspectRecord}/>
        </Drawer>
    );
};

export default HydrantInspectRecordDrawer;