import {Button, DatePicker, Divider, Drawer, Form, Input, message, Modal, Select, Space, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showSuccess} from "../functions";
import {addInspectTask, addRegularInspectPlan, getUserList} from "../../api";
import {RangePickerProps} from 'antd/es/date-picker';
import {iHydrant} from "../../interface";
import AddInspectRecordModal from "../addInspectRecord";
import {ExclamationCircleFilled} from "@ant-design/icons";
import moment from "moment";


const AddInspectTaskDrawer = ({
                                 open,
                                 onClose,
                                 handleGetInspectTask
                             }: { open: boolean, onClose: () => void, handleGetInspectTask: any }) => {
    const [form] = Form.useForm();
    const [userOptions, setUserOptions] = useState<any[]>([]);
    const changeName = (e: any) => {
        let username = e.target.value
        if (username) {
            getUserList({username}).then((res: any) => {
                let _user = res?.data?.map((d: any) => {
                    return {
                        label: `姓名:${d?.username}  手机号:${d?.mobile}`,
                        value: d?.userId
                    }
                })
                setUserOptions(_user)
            }).catch((err: any) => {
                console.log(err);
            })
        }
    }

    const handleTaskTimeChange = (dates: any, dateStrings: [string, string]) => {
        if (dates?.length > 0) {
            let startTime = dates[0].$d.valueOf();
            const now = new Date();
            const nextHour = new Date(now.getTime() + 60 * 60 * 1000);
            if (startTime > nextHour) {
                form.setFieldsValue({taskTime:dates})
            } else {
                let nextHourStr= moment(nextHour).format('YYYY-MM-DD HH:mm:ss')
                message.warning({content: `任务开始时间必须晚于${nextHourStr}(当前时间的后一小时)`, duration: 2.5})
                form.setFieldsValue({taskTime: ""})
            }
        } else {
            form.setFieldsValue({taskTime:""})
        }
    }

    const [loading, setLoading] = useState<boolean>(false);
    const pushData = (values: any) => {
        setLoading(true);
        addInspectTask(values).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                handleGetInspectTask().then(() => {
                    showSuccess(res?.msg);
                    onClose();
                    form.resetFields();
                    setUserOptions([]);
                    setHydrantList([]);
                })
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }
    const handleAddNewInspectTask = () => {
        form.validateFields().then((value) => {
            let newInspectTask = {
                ...value,
                taskStartTime: value?.taskTime[0]?.$d.valueOf(),
                taskEndTime: value?.taskTime[1]?.$d.valueOf(),
            }
            if (hydrantList?.length > 0) {
                let _newInspectTask = {
                    ...newInspectTask,
                    inspectionRecords:hydrantList?.map((i)=>{return {
                        hydrantId:i?.hydrantId,
                        userId:i?.userId,
                        // recordState:0
                    }})
                }
                pushData(_newInspectTask);
            } else {
                Modal.confirm({
                    title: '提示',
                    icon: <ExclamationCircleFilled/>,
                    content: '当前巡检清单为空, 是否继续提交该任务?',
                    onOk() {
                        pushData(newInspectTask);
                    },
                });
            }
        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    //巡检清单记录新增
    const [addRecordModalVisible, setAddRecordModalVisible] = useState<boolean>(false);
    const handleCloseAddRecordModal = () => {
        setAddRecordModalVisible(false)
    }

    const [hydrantList, setHydrantList] = useState<iHydrant[] | []>([])
    const handleInsertHydrantList = (records: iHydrant[]) => {
        setHydrantList((prevState: any) => {
            return [...prevState, ...records];
        });
        setAddRecordModalVisible(false);
        showSuccess('添加成功')
    }

    const columns: any = [
        {
            title: '名称',
            dataIndex: 'hydrantName',
            key: 'hydrantName',
        },
        {
            title: '地址',
            dataIndex: 'hydrantAddress',
            key: 'hydrantAddress'
        },
        {
            title: '二维码',
            dataIndex: 'qrCode',
            key: 'qrCode',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (_: any, {hydrantId}: any) => (
                <Button type={'link'} danger onClick={() => {
                    setHydrantList((pre: any) => {
                        let remainArr = pre?.filter((a: any) => a?.hydrantId !== hydrantId);
                        return remainArr
                    })
                }}>删除</Button>
            )
        },
    ];


    return (
        <div>
            <Drawer open={open} onClose={() => {
                onClose();
                setLoading(false);
                setUserOptions([]);
            }} title={'任务新增'} width={'100vw'} extra={
                <Space>
                    <Button loading={loading} style={{width: '200px'}} type="primary"
                            onClick={debounce(handleAddNewInspectTask, 1000)}>
                        确认提交
                    </Button>
                </Space>
            }>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="taskName"
                        label="任务名称"
                        rules={[
                            {
                                required: true, message: "请输入任务名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入任务名称"/>
                    </Form.Item>

                    <Form.Item
                        name="taskTime"
                        label="任务起止时间"
                        rules={[
                            {
                                required: true, message: "请选择起止时间"
                            },
                        ]}
                    >
                        <DatePicker.RangePicker style={{width: "100%"}} showTime={{format: 'HH'}}
                                                format="YYYY-MM-DD HH:00"
                                                placeholder={['开始时间', '结束时间']}
                                                onChange={handleTaskTimeChange}/>
                    </Form.Item>

                    <Form.Item
                        name="userId"
                        label="负责人"
                        rules={[
                            {
                                required: true, message: "请选择任务负责人"
                            },
                        ]}
                    >
                        <Select
                            allowClear={true}
                            placeholder={"请输入用户名查询并选择"}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0'}}/>
                                    <Space style={{padding: '4px 8px 4px '}}>
                                        <Input
                                            allowClear
                                            placeholder="请输入用户名"
                                            onChange={debounce(changeName, 600)}
                                        />

                                    </Space>
                                </>
                            )}
                            options={userOptions}
                        />
                    </Form.Item>


                    <Form.Item
                        name="taskRemark"
                        label="备注"
                    >
                        <Input placeholder="请输入任务备注"/>
                    </Form.Item>

                    <Form.Item
                        name="regularHydrantList"
                        label="巡检清单"
                    >
                        <Button type={'default'} style={{width: '200px'}} onClick={() => {
                            setAddRecordModalVisible(true);
                        }}>添加</Button>

                        {hydrantList?.length > 0 && (
                            <Table pagination={{pageSize: 5}} style={{marginTop: '15px'}} rowKey={'hydrantId'}
                                   columns={columns} dataSource={hydrantList}
                            />
                        )}
                    </Form.Item>
                </Form>

                <AddInspectRecordModal open={addRecordModalVisible} onCancel={handleCloseAddRecordModal}
                                       insertRecords={handleInsertHydrantList}
                                       initHydrantList={hydrantList}
                />

            </Drawer>
        </div>
    );
};

export default AddInspectTaskDrawer;